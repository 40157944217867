import React from 'react';
import '../style/footer.css';

class Footer extends React.Component{
    render(){
        return(
            <div className='footer text-white fs-1'>
                <div>Thanks For Visiting!</div>
            </div>
        );//return end
    }//render end
}//class Footer end

export default Footer;
